<template>
  <header class="cjdse ctxdt cq235 cj54k ckhiu">
    <div class="c51vq cfz40 c9l28 copk9">
      <div class="chnmm c6433 cyo1m cboo1 cy4zf cgb32 ch8zk c9iyz cpa9w cqo02 ch7ua case2 co7ag c80ml cq997 cmhvg cnvmw cdyyz cfqvj crr7c ch6nl">

        <!-- Site branding -->
        <div class="co7ag cxnxt cfqvj">
          <!-- Logo -->
          <router-link
              :to="{ name: 'HomePage'}"
              aria-label="Edinte, Lda"
              class="cw01g"
          >
            <img src="@/assets/images/logo/Perfil%20(1).jpg" alt="Edinte, Lda">
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="cz9md cwr3y ctqun">
          <!-- Desktop menu links -->
          <ul class="c3sc6 co7ag cfpf9 c6025 c8gkw cx47t cfqvj calfh">
            <li class="ch6nl cd3au">
              <router-link
                  :to="{ name: 'HomePage'}"
                  class="cd0kb cnkw4 co7ag cj7l9 cfqvj"
              >
                Início
              </router-link>
            </li>
            <li class="ch6nl cd3au">
              <router-link
                  :to="{ name: 'HomePage', hash: '#services' }"
                  class="cd0kb cnkw4 co7ag cj7l9 cfqvj"
              >
                Serviços
              </router-link>
            </li>
            <li class="ch6nl cd3au">
              <router-link
                  :to="{ name: 'PortfolioPage'}"
                  class="cd0kb cnkw4 co7ag cj7l9 cfqvj"
              >
                Portfólio
              </router-link>
            </li>
            <li class="ch6nl cd3au">
              <router-link
                  :to="{ name: 'AboutPage'}"
                  class="cd0kb cnkw4 co7ag cj7l9 cfqvj"
              >
                Sobre
              </router-link>
            </li>
            <li class="ch6nl cd3au">
              <router-link
                  :to="{ name: 'HomePage', hash: '#contact' }"
                  class="cd0kb cnkw4 co7ag cj7l9 cfqvj"
              >
                Contacto
              </router-link>
            </li>
          </ul>
        </nav>

        <!-- Desktop sign in links -->
        <ul class="co7ag cccmg cxnxt cdyyz cfqvj">
          <li>
            <router-link
                :to="{ name: 'HomePage', hash: '#contact' }"
                class="c5ivi c7wsw cjx4i c22el cpx7z cl5kc cgko6 cebtw c0ood cuq9y cy22h c6i6l cdxq3 cksb3 ctxdt cpx7z cavkg cs8g5"
            >
              <span class="co7ag cw01g cnvmw">
                      Começar <span class="cg0uc cy04x csq29 cxzl0 cb0m7">-&gt;</span>
                  </span>
            </router-link>
          </li>
        </ul>

        <!-- Mobile menu -->
        <div class="cv9pk cfqvj" :class="{ 'expanded': expanded }">
          <!-- Hamburger button -->
          <button class="c3sc6 coqce co7ag cw01g ckgn9 cj7l9 cfm51 cavkg c3rua cm56k" aria-controls="mobile-nav" :aria-expanded="expanded" @click.stop="toggleMenu">
            <span class="czrcx">Menu</span>
            <svg class="c6t7c co06i car7a c3xya" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <rect class="cujva cp9h9 czpdy c7cus cs9vw cc7mo cyalt" y="2" x="7" width="9" height="2" rx="1"></rect>
              <rect class="cdl3a c1bf3 cs9vw cc7mo cyalt" y="7" width="16" height="2" rx="1"></rect>
              <rect class="cujva cvyw4 czpdy c7cus cs9vw cc7mo cyalt" y="12" width="9" height="2" rx="1"></rect>
            </svg>
          </button>

          <!-- Mobile navigation -->
          <nav id="mobile-nav" class="chnmm c6433 cyo1m cboo1 cy4zf cgb32 ch8zk cpa9w ch7ua case2 c1wcu cmhvg czzwo c51rc cfm51 coaxx ctxdt chikj" v-show="expanded" @click="expanded = false">
            <ul class="c8gkw c7hzr">
              <li>
                <router-link
                    :to="{ name: 'HomePage'}"
                    class="ct2t1 cnkw4 cm8c3 c869h cfqvj c1tg7"
                >
                  Início
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'HomePage', hash: '#services' }"
                    class="ct2t1 cnkw4 cm8c3 c869h cfqvj c1tg7"
                >
                  Serviços
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'PortfolioPage'}"
                    class="ct2t1 cnkw4 cm8c3 c869h cfqvj c1tg7"
                >
                  Portfólio
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'AboutPage'}"
                    class="ct2t1 cnkw4 cm8c3 c869h cfqvj c1tg7"
                >
                  Sobre
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'HomePage', hash: '#contact' }"
                    class="ct2t1 cnkw4 cm8c3 c869h cfqvj c1tg7"
                >
                  Contacto
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'MyHeader',
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    AOS.init();
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscapeKey);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscapeKey);
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded;
    },
    handleClickOutside(event) {
      if (this.expanded && !this.$el.contains(event.target)) {
        this.expanded = false;
      }
    },
    handleEscapeKey(event) {
      if (this.expanded && event.key === 'Escape') {
        this.expanded = false;
      }
    },
  },
};
</script>

<style scoped>
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
</style>
