<template>
  <main class="calfh">

    <section class="cnvmw">

      <div class="c6t7c czzwo cb1an c8hf0 c1fvq cz0xq cl1vg" aria-hidden="true">
        <img class="cd1c2" src="@/assets/images/stripes.svg" width="768" height="432" alt="Stripes">
      </div>

      <div class="c6t7c czzwo cbx03 cb1an c5f38 cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c3cj0 clmcj"></div>
      </div>
      <div class="c6t7c czzwo cb1an caonz cc829 cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 c3cj0 clmcj">
        </div>
      </div>
      <div class="c6t7c czzwo cb1an c0pvq ccg3y cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 c3cj0 clmcj">
        </div>
      </div>

      <div class="c9l28 c51vq copk9 cfz40">

        <div class="c9l28 ce5lh ccves clphe cwv1a">

          <div class="ckgn9">
            <h1 class="czoh8 c8fk6 ceq2f cbl2u cu5ex cqlk5">Portfólio de Projectos</h1>
            <div class="c9l28 ce5lh">
              <p class="c125h cnkw4">Cada projeto é uma prova do nosso esforço contínuo em fornecer soluções de alta qualidade que atendem às necessidades e expectativas dos nossos clientes. De desenvolvimento de aplicativos móveis a sistemas de gestão, cada trabalho é realizado com precisão técnica e uma atenção cuidadosa aos detalhes.</p>
            </div>
          </div>

        </div>

      </div>
    </section>

    <div class="c9l28 c51vq copk9 cfz40">
      <div class="cpyqd c7vra">
        <div class="cn2tb">
          <section>
            <div class="c3hsv cx47t cy4l5 cgxz0 cepbw">
              <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                  <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                </svg>
                <div class="cp91v cw01g">
                  <div class="cnvmw">
                    <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-creditflow.png" width="48" height="48" alt="Icon 01">
                  </div>
                </div>
                <h3 class="css88">
                  <a class="cbl2u cpa9w ch7ua" target="_blank" href="https://www.creditflowmz.com/">Creditflow</a>
                </h3>
                <p class="c8gkw cnkw4">Sistema de gestão de microcrédito que inclui cadastro de clientes, processamento de empréstimos, contabilidade, relatórios detalhados, conformidade regulatória e muito mais...</p>
              </article>
              <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                  <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                </svg>
                <div class="cp91v cw01g">
                  <div class="cnvmw">
                    <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-smartwater.png" width="44" height="44" alt="Icon 02">
                  </div>
                </div>
                <h3 class="css88">
                  <a class="cbl2u cpa9w ch7ua" href="#0">Smartwater</a>
                </h3>
                <p class="c8gkw cnkw4">Sistema de gestão de distribuição de água que inclui controle de recursos, monitoramento de consumo e geração de relatórios detalhados para otimização do uso e manutenção da infraestrutura...</p>
              </article>
              <article class="cnvmw cfqvj ctxdt ctath cq997 ck0r3 cbsrt cmhvg ch8zk cj7l9 cboo1 cpa9w ch7ua cy4zf case2 cgb32 chnmm cyo1m c6433 c8zu4 cavkg">
                <svg class="czzwo cja5d cd3a4 cy04x c1k1z" xmlns="http://www.w3.org/2000/svg" width="9" height="9">
                  <path class="cd9bt" d="M1.065 9 0 7.93l6.456-6.46H1.508L1.519 0H9v7.477H7.516l.011-4.942L1.065 9Z"></path>
                </svg>
                <div class="cp91v cw01g">
                  <div class="cnvmw">
                    <img class="cqu42 cmhvg ch8zk" src="@/assets/images/portfolio/logo-sintega.png" width="48" height="48" alt="Icon 03">
                  </div>
                </div>
                <h3 class="css88">
                  <a class="cbl2u cpa9w ch7ua" href="#0">Sintega</a>
                </h3>
                <p class="c8gkw cnkw4">Sistema integrado de gestão académica que abrange matrícula, registros, avaliação, controle financeiro, relatórios e comunicação entre alunos, professores, administração e muito mais...</p>
              </article>

            </div>
          </section>
        </div>
      </div>
    </div>

    <section class="cnvmw">
      <div class="czzwo cb1an c8hf0 c1fvq cz0xq cubfr" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 cvlc2 clmcj"></div>
      </div>
      <div class="c9l28 c51vq copk9 cfz40">
        <div class="cltfm ckgn9 cnwfh">
          <div class="cu6qt cyhva">
            <h2 class="czoh8 cx14y cbl2u cs9iq c6lc4">Tem algum projecto em mente?</h2>
            <div class="c9l28 cdiu1 cfr0l ct30u c8hib">
              <router-link
                  :to="{ name: 'HomePage', hash: '#contact' }"
                  class="cs8g5 cktd8 ctxdt cebtw c0ood cy22h cgko6 cuq9y c6i6l cpx7z cl5kc cavkg cksb3 cdxq3"
              >
                 <span class="cnvmw cw01g co7ag">
                    Contacte-nos <span class="cb0m7 csq29 cxzl0 cy04x cg0uc">-&gt;</span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
  export default {
    name: 'PortfolioPage',
    head() {
      return {
        title: 'Portfolio - Edinte',
        meta: [
          { name: 'description', content: 'Conheça os serviços oferecidos pela Edinte, incluindo soluções em crédito, gestão de sistemas e mais.' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: 'Portfolio - Edinte' },
          { property: 'og:description', content: 'Conheça os serviços oferecidos pela Edinte, incluindo soluções em crédito, gestão de sistemas e mais.' },
          { property: 'og:url', content: 'https://edinte.com/portfolio' },
          { property: 'og:type', content: 'website' }
        ],
        link: [
          { rel: 'canonical', href: 'https://www.edinte.com/portfolio' }
        ]
      }
    }
  }
</script>
<style scoped>

</style>