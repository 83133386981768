<template>
    <div class="container">
        <div class="not-found">
            <p><strong>404 | NOT FOUND</strong></p>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'Error404Component'
    }
</script>

<style scoped>
    .container
    {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: thin;
    }

    .not-found
    {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p
    {
        color: white;
        font-weight: bold;
        font-size: 2em;
        
    }
    strong
    {
        color: #028ccc;
    }
</style>

