<template>
  <section class="cboo1 cw1n6 cgtys ckgpg cpa9w ch7ua cwsi8 cnvmw">
    <div class="chn41 ctk3h">
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="ckgn9 ce5lh c9l28">
          <h2 class="c6lc4 cbl2u cx14y c0eoy c8fk6">{{ title }}</h2>
        </div>
      </div>
      <div class="cdpbp c3sc6 cnvmw c9l28 cfqvj">
        <div class="c6nlq chtgh czzwo c1fvq" aria-hidden="true">
          <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 c46ek c14i2 c7qgr"></div>
        </div>
        <div class="clq84 czzwo c1fvq" aria-hidden="true">
          <div class="clmcj cqu42 c9itk cvlc2 c14i2 c7qgr"></div>
        </div>
        <div class="czzwo cw1if c1fvq" aria-hidden="true">
          <div class="ck1ij cvu5t cqu42 c1mvy c48hl cuggg"></div>
        </div>
        <div class="cichr cw01g c0oga cyhva ctxdt cu6qt cavkg">
          <div ref="testimonials" class="cai2g ck23p c2lfy cmgqa c3sc6 cf5j0 cfqvj">
            <article
                v-for="(image, index) in images"
                :key="index"
                class="chnmm c6433 cyo1m cboo1 cy4zf cgb32 ch8zk clint cy04x cpa9w ch7ua c2xxi case2 czj5i ck0r3 cyalt c96zk cq997 cmhvg cnvmw ctath c53gg cfqvj cbsrt image-container"
            >
              <img :src="image.src" :alt="image.alt" class="cokse image">
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    images: Array
  }
};
</script>

<style scoped>
  .image-container {
    max-height: 200px;
    max-width: 200px;
  }

  .image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }
</style>

