import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/styles/style.css';
import aos from '@/plugins/aos';
import scrollspy from "@/directives/scrollspy";
import masonry from "@/directives/masonry";

const app = createApp(App);
app.use(router);
app.use(aos);
app.directive("scrollspy", scrollspy);
app.directive("masonry", masonry);
app.mount('#app');