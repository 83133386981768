import { createRouter, createWebHistory } from 'vue-router'
import Error404 from '../components/Error404Component'
import HomePage from "@/pages/Home/HomePage.vue";
import AboutPage from "@/pages/About/AboutPage.vue";
import PortfolioPage from "@/pages/Portfolio/PortfolioPage.vue";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Edinte',
      favicon: 'favicon.png',
      description: 'Bem-vindo à Edinte, onde construímos soluções incríveis.',
    }
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
    meta: {
      title: 'Edinte',
      description: 'Saiba mais sobre a Edinte e nossa missão.',
      favicon: 'favicon.png',

    }
  },
  {
    path: '/portfolio',
    name: 'PortfolioPage',
    component: PortfolioPage,
    meta: {
      title: 'Edinte',
      favicon: 'favicon.png',
      description: 'Confira nossos projectos e portfólio.',
    }
  },
  {
    path: '/:pathHandler(.*)',
    name: 'error404',
    component: Error404,
    meta: { title: 'Not Found', favicon: 'favicon.png' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
})

/** Improves the title by section or router **/
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Edinte'
  const favicon = to.meta.favicon || 'favicon.png'
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = favicon
  document.getElementsByTagName('head')[0].appendChild(link)

  const metaDescription = document.querySelector("meta[name='description']") || document.createElement('meta');
  metaDescription.name = 'description';
  metaDescription.content = to.meta.description || 'Edinte, construindo soluções incríveis.';
  document.getElementsByTagName('head')[0].appendChild(metaDescription);

  next();
})

export default router
