<template>
  <main class="calfh">

    <section class="cnvmw">

      <div class="c6t7c czzwo cb1an c8hf0 c1fvq cz0xq cl1vg" aria-hidden="true">
        <img class="cd1c2" src="@/assets/images/stripes.svg" width="768" height="432" alt="Stripes">
      </div>

      <div class="c6t7c czzwo cbx03 cb1an c5f38 cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c3cj0 clmcj"></div>
      </div>
      <div class="c6t7c czzwo cb1an caonz cc829 cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 c3cj0 clmcj">
        </div>
      </div>
      <div class="c6t7c czzwo cb1an c0pvq ccg3y cz0xq" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 c3cj0 clmcj">
        </div>
      </div>

      <div class="c9l28 c51vq copk9 cfz40">

        <div class="c9l28 ce5lh ccves clphe cwv1a">

          <div class="ckgn9">
            <h1 class="czoh8 c8fk6 ceq2f cbl2u cu5ex cqlk5">Sobre Nós</h1>
            <div class="c9l28 ce5lh">
              <p class="c125h cnkw4">
                Fundada com a missão de fornecer serviços de tecnologia da informação de alta qualidade, a Edinte se destaca por seu compromisso com a excelência e a satisfação do cliente.
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section class="cnvmw" id="about">
      <div class="cz0xq c0tot cb1an czzwo c8hf0 c1fvq" aria-hidden="true">
        <div class="ch2p1 clmcj cp9h9 cqu42 c2rc3 cvlc2 c14i2 c7qgr"></div>
      </div>
      <div class="c51vq cfz40 c9l28 copk9">
        <div class="cyhva cu6qt">

          <div class="overflow-hidden ctncy chmjk c2dad cprw1 cho3t cy3v2 ccslj cgnw0 csgrw c8hgl ckow0 cddvk cepbw c22g3 c8fk6 c3hsv">
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="m15.447 6.605-.673-.336a6.973 6.973 0 0 0-.761-1.834l.238-.715a.999.999 0 0 0-.242-1.023l-.707-.707a.995.995 0 0 0-1.023-.242l-.715.238a6.96 6.96 0 0 0-1.834-.761L9.394.552A1 1 0 0 0 8.5-.001h-1c-.379 0-.725.214-.895.553l-.336.673a6.973 6.973 0 0 0-1.834.761l-.715-.238a.997.997 0 0 0-1.023.242l-.707.707a1.001 1.001 0 0 0-.242 1.023l.238.715a6.959 6.959 0 0 0-.761 1.834l-.673.336a1 1 0 0 0-.553.895v1c0 .379.214.725.553.895l.673.336c.167.653.425 1.268.761 1.834l-.238.715a.999.999 0 0 0 .242 1.023l.707.707a.997.997 0 0 0 1.023.242l.715-.238a6.959 6.959 0 0 0 1.834.761l.336.673a1 1 0 0 0 .895.553h1c.379 0 .725-.214.895-.553l.336-.673a6.973 6.973 0 0 0 1.834-.761l.715.238a1.001 1.001 0 0 0 1.023-.242l.707-.707c.268-.268.361-.664.242-1.023l-.238-.715a6.959 6.959 0 0 0 .761-1.834l.673-.336A1 1 0 0 0 16 8.5v-1c0-.379-.214-.725-.553-.895ZM8 13a5 5 0 1 1 .001-10.001 5 5 0 0 1 0 10.001Z"></path>
                </svg>
                <span>Nossa Missão</span>
              </h3>
              <p class="c68fx cnkw4">Nossa missão na Edinte é capacitar empresas em Moçambique com soluções de TI inovadoras e personalizadas, simplificando processos, melhorando a comunicação e impulsionando o crescimento.
                Nos dedicamos a fornecer serviços de alta qualidade que transformam desafios em oportunidades de sucesso.</p>
            </article>
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="12">
                  <path d="M2 0a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2H2Zm0 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Zm1-3a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H3Z"></path>
                </svg>
                <span>Visão</span>
              </h3>
              <p class="c68fx cnkw4">Ser a referência líder em soluções de TI em Moçambique, reconhecida por inovação e excelência. Queremos transformar negócios através da tecnologia,
                empoderando empresas com ferramentas robustas e personalizadas, e contribuindo para o desenvolvimento econômico e social.</p>
            </article>
            <article>
              <h3 class="co7ag cbhyt c54bg cskx6 cfqvj">
                <svg class="c8y72" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="M14.75 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 13.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.5 14.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM1.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z"></path>
                </svg>
                <span>Nossos Valores</span>
              </h3>
              <p class="c68fx cnkw4">
                <ul style="list-style: outside" class="c68fx cnkw4">
                  <li><strong>Inovação:</strong> Estar na vanguarda das tecnologias emergentes;</li>
                  <li><strong>Excelência:</strong> Superar as expectativas dos clientes com serviços de alta qualidade;</li>
                  <li><strong>Colaboração:</strong> Trabalhar em estreita parceria com os clientes para entender e atender suas necessidades;</li>
                  <li><strong>Integridade:</strong> Agir com transparência e honestidade, construindo confiança em todas as nossas relações.</li>
                </ul>
              </p>
            </article>
          </div>

        </div>
      </div>
    </section>

    <section class="cnvmw">
      <div class="czzwo cb1an c8hf0 c1fvq cz0xq cubfr" aria-hidden="true">
        <div class="c14i2 c7qgr cqu42 ch2p1 cp9h9 c2rc3 cvlc2 clmcj"></div>
      </div>
      <div class="c9l28 c51vq copk9 cfz40">
        <div class="cltfm ckgn9 cnwfh">
          <div class="cu6qt cyhva">
            <h2 class="czoh8 cx14y cbl2u cs9iq c6lc4">Tem algum projecto em mente?</h2>
            <div class="c9l28 cdiu1 cfr0l ct30u c8hib">
              <router-link
                  :to="{ name: 'HomePage', hash: '#contact' }"
                  class="cs8g5 cktd8 ctxdt cebtw c0ood cy22h cgko6 cuq9y c6i6l cpx7z cl5kc cavkg cksb3 cdxq3"
              >
                <span class="cnvmw cw01g co7ag">
                    Contacte-nos <span class="cb0m7 csq29 cxzl0 cy04x cg0uc">-&gt;</span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
  export default {
    name: 'AboutPage',
    head() {
      return {
        title: 'Sobre nos - Edinte',
        meta: [
          { name: 'description', content: 'Conheça os serviços oferecidos pela Edinte, incluindo soluções em crédito, gestão de sistemas e mais.' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: 'Serviços - Edinte' },
          { property: 'og:description', content: 'Conheça os serviços oferecidos pela Edinte, incluindo soluções em crédito, gestão de sistemas e mais.' },
          { property: 'og:url', content: 'https://edinte.com/about' },
          { property: 'og:type', content: 'website' }
        ],
        link: [
          { rel: 'canonical', href: 'https://www.edinte.com/about' }
        ]
      }
    }
  }
</script>

<style scoped>

</style>