export default {
	mounted(el) {
		const masonryLayout = (parent) => {
			const childElements = Array.from(parent.children);
			const gapSize = parseInt(
				window.getComputedStyle(parent).getPropertyValue("grid-row-gap")
			);

			childElements.forEach((el) => {
				let previous = el.previousSibling;
				while (previous) {
					if (previous.nodeType === 1) {
						el.style.marginTop = 0;
						if (elementLeft(previous) === elementLeft(el)) {
							el.style.marginTop =
								-(elementTop(el) - elementBottom(previous) - gapSize) + "px";
							break;
						}
					}
					previous = previous.previousSibling;
				}
			});
		};

		const elementLeft = (el) => {
			return el.getBoundingClientRect().left;
		};

		const elementTop = (el) => {
			return el.getBoundingClientRect().top + window.scrollY;
		};

		const elementBottom = (el) => {
			return el.getBoundingClientRect().bottom + window.scrollY;
		};

		const initMasonryLayout = () => {
			masonryLayout(el);
		};

		window.addEventListener('load', initMasonryLayout);
		window.addEventListener('resize', initMasonryLayout);

		initMasonryLayout();
	},
};