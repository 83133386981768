import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
	install: (app) => {
		AOS.init({
			once: true,
			disable: "phone",
			duration: 700,
			easing: "ease-out-cubic",
		});
		app.config.globalProperties.$AOS = AOS;
	},
};